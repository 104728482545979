.list-table {
  td {
    border-left: none;
    border-right: none;
  }
  th {
    background-color: #ececec;
    border: none;
  }
}

.img-violate {
  width: 66px;
}

.p-timeline-event-marker {
  background-color: #0c4da2;
  border: none;
}
.p-timeline-event-connector {
  background-color: #0c4da2;
}
.p-timeline-event-opposite {
  flex: none;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 50px 100px;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 8px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.p-dialog {
  border-radius: 18px;
}

.custom-form-grid {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.form-field {
  flex: 1;
  max-width: calc(33.333% - 1rem);
}
