.message {
  position: relative;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.message-user {
  align-self: flex-end;
  background-color: #059669;
  color: white;
}

.message-support {
  align-self: flex-start;
  background-color: #f1f1f1;
  color: black;
}

.message-time {
  align-self: flex-end;
  font-size: 0.8em;
  color: #aaa;
  margin-top: 5px;
}

.custom-container {
  height: 800px;
  font-size: 1.2rem;
}

.custom-button {
  height: 40px;
  width: 100%;
  font-size: 1.2rem;
}

.custom-header {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.custom-mailbox {
  font-size: 1.2rem;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.custom-mailbox-icon {
  font-size: 1.2rem;
}

.hover-effect:hover {
  background-color: #e0f7e0;
  color: #4caf50;
}

.hover-effect:hover .custom-mailbox-icon {
  color: #4caf50;
}

.hover-effect:hover .absolute {
  color: #4caf50;
}

.custom-file-upload {
  margin-right: 10px;
}

.custom-label {
  display: block;
  margin-bottom: 5px;
}

.tieuDe {
  margin-top: -1.5rem !important;
  margin-bottom: 10px;
}

.fixed-image-size img {
  max-width: 100px;
  max-height: 100px;
}

.message-image {
  align-self: flex-end;
  background-color: #059669;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message-image img {
  max-width: 200px;
  height: auto;
}


.translate-button {
  margin-top: 10px;
  padding-right: 5px;
  cursor: pointer;
}

.custom-mailbox.active {
  background-color: #e0f7fa;
}

.custom-mailbox {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.hover-effect:hover {
  background-color: #f1f1f1;
}

/* Support.css */

.translate-button {
  color: #0C4DA2;
  cursor: pointer;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.translation-box {
  background-color: #f1f1f1;
  margin-top: 5px;
  border-radius: 5px;
  position: relative;
  /* margin-top: 5px; */
  padding-right: 10px;
}

.translate-button-2 {
  color: #0C4DA2;
  cursor: pointer;
}