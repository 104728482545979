.header-container {
  display: flex;
  align-items: center;
  gap: 2rem;
  border-radius: 8px;
  background-color: white;
  padding: 12px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 40%;

    svg {
      height: 30px;
      width: 30px;
    }
  }
}

.content {
  font-weight: 700;
  font-size: 22px;
  color: #202224;

  .content-p {
    font-weight: 700;
    font-size: 16px;
    opacity: 70%;
    margin-bottom: 12px;
  }
}

.new-content {
  padding: 35px 30px;
  background-color: white;
  border-radius: 8px;

  p {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  li {
    padding: 0px 12px 8px 12px;
  }
}

.col {
  padding-right: 20px;
  padding-left: 20px;
}

.element-p {
  color: #5c5c5c;
  font-size: 18px;
  font-weight: 700;
}

.btn button {
  padding: 2px 16px;
  border-radius: 4px;
  color: white;
}

.dot {
  content: "";
  height: 12px;
  width: 12px;
  background-color: black;
  border-radius: 50%;
}

.noti-item {
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
}

.noti-item:hover {
  background-color: #e7eef6;
}

.btn-active {
  background-color: #10b981;
}

.btn-inactive {
  background-color: #a2a2a2;

  &:hover {
    background-color: #059669;
  }
}

.year-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  button {
    background-color: #10b981;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #059669;
    }
  }

  span {
    margin: 0 1rem;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}